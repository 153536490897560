/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; }
body { margin: 0 !important; font-family: 'Nunito Sans', sans-serif !important;}

h1,h2,h3,h4,h5,h5,b,strong,.mdc-snackbar__label {
  font-family: 'Nunito Sans', sans-serif !important;
}

p, a, span, ul, li {
  font-family: 'Poppins', sans-serif;
}

.main-content {
  height: 100%;
  position: relative;
  overflow-x: auto;
}

.cursor-p {
  cursor: pointer !important;
}

input {
  font-family: 'Poppins', sans-serif;
  &::placeholder {
    font-family: 'Poppins', sans-serif;
  }
}

label,
.mat-mdc-paginator-container > * {
  font-family: 'Poppins', sans-serif;
}

table {
  tr {
    th {
      font-weight: 600 !important;
      font-size: 1rem !important;
      font-family: 'Nunito Sans', sans-serif !important;
      vertical-align: middle;
      text-align: center !important;
    }
    td {
      font-family: 'Poppins', sans-serif;
      vertical-align: middle;
      text-align: center !important;
    }
  }
}

.message-container .user-message p,
.message-container .user-message h1,
.message-container .user-message h2,
.message-container .user-message h3,
.message-container .user-message h4,
.message-container .user-message h5,
.message-container .user-message h6 {
  margin-bottom: 0;
}

.message-container .message p,
.message-container .message h1,
.message-container .message h2,
.message-container .message h3,
.message-container .message h4,
.message-container .message h5,
.message-container .message h6 {
  margin-bottom: 0;
}
